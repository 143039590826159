export const validateAddress = (address) => {
  if (address === "") {
    return { success: false, message: "Postcode is Required!" };
  }
  const numericRegex = /^[0-9]+$/;
  if (numericRegex.test(address) == false) {
    return { success: false, message: "Please enter a valid Postcode!" };
  }
  return { success: true, message: null };
};
export const validateEmail = (email) => {
  var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  if (!email || email === "") {
    return { success: false, message: "Email address is required!" };
  }
  if (reg.test(email) == false) {
    return { success: false, message: "Invalid email address!" };
  }
  return { success: true, message: null };
};
export const validateFirstName = (firstName) => {
  if (firstName === "") {
    return { success: false, message: "First name is required!" };
  }
  return { success: true, message: null };
};
export const validateLastName = (lastName) => {
  if (lastName === "") {
    return { success: false, message: "Last name is required!" };
  }
  return { success: true, message: null };
};
export const validateComment = (comment) => {
  if (comment === "") {
    return { success: false, message: "Comment is required!" };
  }
  return { success: true, message: null };
};
export const validatePhone = (phone) => {
  if (phone === "") {
    return { success: false, message: "Phone number is required!" };
  }
  return { success: true, message: null };
};
export const validateLocation = (location) => {
  if (location === "") {
    return { success: false, message: "Location is required!" };
  }
  return { success: true, message: null };
};
export const validateDate = (date) => {
  if (date === "") {
    return { success: false, message: "Date is required!" };
  }
  return { success: true, message: null };
};
export const validateSite = (site) =>{
    if (site === "" || site ==="Choose an option") {
        return { success: false, message: "Please select a site!" };
      }
      return { success: true, message: null };
};
export const validateFile = (file) => {
  if (file == null) {
    return { success: false, message: "Receipt is required!" };
  }
  return { success: true, message: null };
};
export const validateChecked = (checked) => {
  if (!checked) {
    return { success: false, message: "This field is required" };
  }
  return { success: true, message: null };
};
