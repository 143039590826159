import React, { useEffect, useRef, useState } from "react";
import styles from "./pages.module.css";
import { NavLink } from "react-router-dom";
import {
  validateFirstName,
  validateLastName,
  validateEmail,
  validatePhone,
  validateLocation,
  validateDate,
  validateFile,
  validateChecked,
} from "../config/validation";
import { BASE_URL, SITE_URL } from "../config/config";
import axios from "axios";
import { Helmet } from "react-helmet";

const ChristmasGiveaway = () => {
  useEffect(() => {
    document.title = "Christmas Giveaway | Budget Petrol";
  }, []);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    date: "",
    location: "",
  });
  const [validationError, setValError] = useState({
    invalidEmail: null,
    invalidFirstName: null,
    invalidLastName: null,
    invalidPhone: null,
    invalidDate: null,
    invalidLocation: null,
    invalidReceipt: null,
    invalidCheked: null,
  });
  const [isChecked, setIsChecked] = useState(false);
  const [checkError, setCheckError] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState(false);
  const [submitMsg, setMessage] = useState(null);
  const dateInputRef = useRef(null);
  const validateContact = (data) => {
    if (
      !validateFirstName(data.first_name).success ||
      !validateLastName(data.last_name).success ||
      !validateEmail(data.email).success ||
      !validatePhone(data.phone).success ||
      !validateDate(data.purchase_date).success ||
      !validateLocation(data.store_location).success ||
      !validateFile(selectedFile).success ||
      !validateChecked(isChecked).success
    ) {
      setValError({
        ...validationError,
        invalidFirstName: validateFirstName(data.first_name).message,
        invalidLastName: validateLastName(data.last_name).message,
        invalidEmail: validateEmail(data.email).message,
        invalidPhone: validatePhone(data.phone).message,
        invalidDate: validateDate(data.purchase_date).message,
        invalidLocation: validateLocation(data.store_location).message,
        invalidReceipt: validateFile(selectedFile).message,
        invalidCheked: validateChecked(isChecked).message,
      });
      if (isFutureDate(data.purchase_date)) {
        setValError({
          ...validationError,
          invalidDate: "Please enter a valid date",
        });
        //return false;
      }
      return false;
    } else {
      setValError({
        invalidEmail: null,
        invalidFirstName: null,
        invalidLastName: null,
        invalidPhone: null,
        invalidDate: null,
        invalidLocation: null,
        invalidReceipt: null,
        invalidCheked: false,
      });
      return true;
      //setCheckError(false);
    }
    // if (isFutureDate(data.purchase_date)) {
    //   setValError({
    //     ...validationError,
    //     invalidDate: "Please enter a valid date",
    //   });
    //   return false;
    // } else {
    //   setValError({
    //     ...validationError,
    //     invalidDate: null,
    //   });
    // }
    // return true;
  };
  const isFutureDate = (dateString) => {
    const currentDate = new Date();
    const selectedDate = new Date(dateString);
    return selectedDate > currentDate;
  };
  const handleContactForm = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      setFileError(true);
    }
    const formData = new FormData(e.target);
    formData.append("receipt", selectedFile);
    const data = Object.fromEntries(formData.entries());
    const newData = {
      model: "giveawayForm",
      firstname: data.first_name,
      lastname: data.last_name,
      email: data.email,
      phone: data.phone,
      fuelpurchaseDate: data.purchase_date,
      storeLocation: data.store_location,
      receipt: selectedFile,
    };
    const sendData = new FormData();
    for (const key in newData) {
      sendData.append(key, newData[key]);
    }
    console.log(data);
    if (validateContact(data)) {
      const response = await axios({
        method: "post",
        url: BASE_URL,
        data: sendData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log("RESPONSE :========", response);
      if (response.data.returnCode === 1) {
        setMessage("Thank you for your message. It has been sent!");
      } else {
        setMessage("Could not deliver your message!");
      }
      setTimeout(() => {
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          date: "",
          location: "",
        });
        const fileInput = document.querySelector('input[type="file"]');
        fileInput.value = "";
        setSelectedFile(null);
        setIsChecked(false);
        setFileError(false);
      }, 1500);
    }
    else{
      setMessage("");
    }
  };

  // useEffect(() => {
  //   const setMaxDate = () => {
  //     const currentDate = new Date().toISOString().split("T")[0];
  //     const dateInput = document.querySelector("input[type='date']");
  //     dateInput.max = currentDate;
  //   };
  //   setMaxDate();
  // }, []);

  const handleDateClick = () => {
    const dateInput = document.getElementById("dateInput");
    dateInput.click();
    // dateInputRef.current.click();
  };

  const [fnameFocus, setFnameFocus] = useState(false);
  const [lnameFocus, setLnameFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [phoneFocus, setPhoneFocus] = useState(false);
  const [locationFocus, setLocationFocus] = useState(false);
  const [receiptFocus, setReceiptFocus] = useState(false);
  const [checkedFocus, setCheckedFocus] = useState(false);
  return (
    <>
      <Helmet>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Giveaway - Budget Petrol" />
        <meta property="og:url" content={`${SITE_URL}giveaway`} />
        <meta property="og:site_name" content="BUDGET PETROL" />
        <meta property="article:modified_time" content="2024-05-22T06:01:40+00:00" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="robots" content="index, follow"/>
      </Helmet>
      <div className={styles.giveaway_page}>
        <div className={styles.breadcrumb_div}>
          <div className={styles.container}>
            <p>
              <NavLink to="/">Home</NavLink>
              <span>Christmas Giveaway</span>
            </p>
          </div>
        </div>
        <div className={`${styles.giveaway_main} ${styles.getInTouch_section}`}>
          <div className={styles.container}>
            <div className={styles.giveaway_form}>
              <div>
                <h2>
                  Christmas <span>Giveaway</span>
                </h2>
              </div>
              <div className={styles.input_form}>
                <form onSubmit={handleContactForm}>
                  <div className={styles.name_field}>
                    <div
                      className={
                        fnameFocus
                          ? `${styles.input_focus} ${styles.value_focus} ${styles.first_name_field}`
                          : formData.firstName !== ""
                          ? `${styles.input_focus} ${styles.value_focus} ${styles.phone_field}`
                          : `${styles.first_name_field}`
                      }
                    >
                      <label>
                        First Name <em>*</em>
                      </label>
                      <input
                        type="text"
                        name="first_name"
                        value={formData.firstName}
                        onFocus={() => setFnameFocus(true)}
                        onBlur={() => setFnameFocus(false)}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            firstName: e.target.value,
                          })
                        }
                        className={
                          validationError.invalidFirstName && styles.error
                        }
                      />
                      {validationError.invalidFirstName && (
                        <p className={styles.error_txt}>
                          {validationError.invalidFirstName}
                        </p>
                      )}
                    </div>
                    <div
                      className={
                        lnameFocus
                          ? `${styles.input_focus} ${styles.value_focus} ${styles.last_name_field}`
                          : formData.lastName !== ""
                          ? `${styles.input_focus} ${styles.value_focus} ${styles.phone_field}`
                          : `${styles.last_name_field}`
                      }
                    >
                      <label>
                        Last Name<em>*</em>
                      </label>
                      <input
                        type="text"
                        name="last_name"
                        value={formData.lastName}
                        onFocus={() => setLnameFocus(true)}
                        onBlur={() => setLnameFocus(false)}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            lastName: e.target.value,
                          })
                        }
                        className={
                          validationError.invalidLastName && styles.error
                        }
                      />
                      {validationError.invalidLastName && (
                        <p className={styles.error_txt}>
                          {validationError.invalidLastName}
                        </p>
                      )}
                    </div>
                  </div>
                  <div
                    className={
                      emailFocus
                        ? `${styles.input_focus} ${styles.value_focus} ${styles.email_name_field}`
                        : formData.email !== ""
                        ? `${styles.input_focus} ${styles.value_focus} ${styles.phone_field}`
                        : `${styles.email_name_field}`
                    }
                  >
                    <label>
                      Email<em>*</em>
                    </label>
                    <input
                      type="text"
                      name="email"
                      value={formData.email}
                      onFocus={() => setEmailFocus(true)}
                      onBlur={() => setEmailFocus(false)}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          email: e.target.value,
                        })
                      }
                      className={validationError.invalidEmail && styles.error}
                    />
                    {validationError.invalidEmail && (
                      <p className={styles.error_txt}>
                        {validationError.invalidEmail}
                      </p>
                    )}
                  </div>
                  <div
                    className={
                      phoneFocus
                        ? `${styles.input_focus} ${styles.value_focus} ${styles.phone_field}`
                        : formData.phone !== ""
                        ? `${styles.input_focus} ${styles.value_focus} ${styles.phone_field}`
                        : `${styles.phone_field}`
                    }
                  >
                    <label>
                      Phone<em>*</em>
                    </label>
                    <input
                      type="number"
                      name="phone"
                      value={formData.phone}
                      onFocus={() => setPhoneFocus(true)}
                      onBlur={() => setPhoneFocus(false)}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          phone: e.target.value,
                        })
                      }
                      className={validationError.invalidPhone && styles.error}
                    />
                    {validationError.invalidPhone && (
                      <p className={styles.error_txt}>
                        {validationError.invalidPhone}
                      </p>
                    )}
                  </div>
                  <div
                    className={`${styles.input_focus} ${styles.value_focus} ${styles.date_field}
                        ${styles.phone_field}`}
                    onClick={() => handleDateClick()}
                  >
                    <label>
                      Date of Fuel Purchase<em>*</em>
                    </label>
                    <span className={styles.dating}>
                      <input
                        type="date"
                        id="dateInput"
                        name="purchase_date"
                        ref={dateInputRef}
                        value={formData.date}
                        onChange={(e) =>
                          setFormData({ ...formData, date: e.target.value })
                        }
                        className={validationError.invalidDate && styles.error}
                      />
                    </span>
                    {validationError.invalidDate && (
                      <p className={styles.error_txt}>
                        {validationError.invalidDate}
                      </p>
                    )}
                  </div>
                  <div
                    className={
                      locationFocus
                        ? `${styles.input_focus} ${styles.value_focus} ${styles.location_field}`
                        : formData.date !== ""
                        ? `${styles.input_focus} ${styles.value_focus} ${styles.phone_field}`
                        : `${styles.location_field}`
                    }
                  >
                    <label>
                      Store location<em>*</em>
                    </label>
                    <input
                      type="text"
                      name="store_location"
                      value={formData.location}
                      onFocus={() => setLocationFocus(true)}
                      onBlur={() => setLocationFocus(false)}
                      onChange={(e) =>
                        setFormData({ ...formData, location: e.target.value })
                      }
                      className={
                        validationError.invalidLocation && styles.error
                      }
                    />
                    {validationError.invalidLocation && (
                      <p className={styles.error_txt}>
                        {validationError.invalidLocation}
                      </p>
                    )}
                  </div>
                  <div
                    className={`${styles.input_focus} ${styles.value_focus} ${styles.receipt_field}
                        ${styles.phone_field}`}
                  >
                    <label>
                      Upload Receipt<em>*</em>
                    </label>
                    <span className={styles.lastest_upoload}>
                      {" "}
                      <input
                        type="file"
                        name="upload_receipt"
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                        onFocus={() => setReceiptFocus(true)}
                        onBlur={() => setReceiptFocus(false)}
                        className={
                          validationError.invalidReceipt && styles.error
                        }
                      />
                    </span>
                    {validationError.invalidReceipt && (
                      <p className={styles.error_txt}>
                        {validationError.invalidReceipt}
                      </p>
                    )}
                    {/* {fileError && (
                      <p className={styles.error_txt}>Receipt is required!</p>
                    )} */}
                  </div>
                  <div className={styles.social_links}>
                    <h3>Follow Us On Social Media</h3>
                    <div className={styles.social_mesia}>
                      {" "}
                      <a
                        href="https://www.facebook.com/budgetpetrol"
                        target="_blank"
                        className={styles.fb_link}
                      ></a>
                      <a
                        href="https://www.instagram.com/budgetpetrol/"
                        target="_blank"
                        className={styles.insta_link}
                      ></a>
                    </div>
                  </div>
                  <div className={styles.condition_field}>
                    <div className={`${styles.check_field} ${styles.check_box}`}>
                      {" "}
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                        onFocus={() => setCheckedFocus(true)}
                        onBlur={() => setCheckedFocus(false)}
                        className={
                          validationError.invalidCheked && styles.error
                        }
                      />
                      <p>
                        By checking this box, I agree that all conditions of
                        entry have been complied with and i agree to receive
                        marketing, advertising and promotional information from
                        Gascorp Pty Ltd.(mandatory requirement to check the box)
                      </p>{" "}
                    </div>
                    {validationError.invalidCheked && (
                      <p className={styles.error_txt}>
                        {validationError.invalidCheked}
                      </p>
                    )}
                    {/* {checkError && (
                      <p className={styles.error_txt}>This field is required</p>
                    )} */}
                  </div>
                  <div className={styles.submit_field}>
                    <button type="submit">Submit</button>
                  </div>
                </form>
              </div>
            </div>
            {submitMsg && (
              <div className={styles.sent_msg_section}>
                <p className={styles.submit_msg_section}>{submitMsg}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ChristmasGiveaway);
