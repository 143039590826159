import axios from "axios";
import { put, takeEvery } from "redux-saga/effects";
import { BASE_URL } from "../config/config";
function* homePage(action) {
  if (action === "nearest_place_button") {
    // console.log("Form Data : ", action.data);
  }
}
function* contactForm(action) {
  if (action === "submit_btn") {
    const response = yield axios({
      method: "post",
      url: BASE_URL,
      data: {
        model: "contactForm",
        firstname: action.data.first_name,
        lastname: action.data.last_name,
        email: action.data.email,
        phone: action.data.phone,
        comment: action.data.comment,
      },
    });
    const returnCode = response.data.returnCode;
    yield put({type : "contact_data_res", returnCode});
  }
}

function* mySaga() {
  yield takeEvery("nearest_place_button", homePage);
  yield takeEvery("submit_btn", contactForm);
}

export default mySaga;
