import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "./pages.module.css";

const Error404 = () => {
  const navigate = useNavigate();
  useEffect(()=>{
    document.title="Page Not Found - Budget Petrol"
  },[])
  return (
    <div className={styles.error_page}>
      <div className={styles.breadcrumb_div}>
        <div className={styles.container}>
          <p>
            <NavLink to="/">Home</NavLink>
            <span>404 Error</span>
          </p>
        </div>
      </div>
      <div className={styles.content_div}>
        <div className={styles.container}>
          <div className={styles.err_heading}>
            <h2 className={styles.fuel_txt}>
              404 <span>ERROR</span>
            </h2>
          </div>
          <div className={styles.err_content}>
            <p className={styles.regular_txt}>
              Hey there mate! Your lost treasure is not found here...
            </p>
            <p className={styles.bold_txt}>
              Sorry! The page you are looking for wasn't found!
            </p>
          </div>
          <div className={styles.err_navigate}>
            <button onClick={() => navigate("/")}>Home</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Error404);
