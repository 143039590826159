import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  useLoadScript,
  useJsApiLoader,
  MarkerF,
  InfoWindow,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY } from "../config/config";
import styles from "./component.module.css";
import { setDefaults, fromLatLng, fromAddress } from "react-geocode";
import MarkerIcon from "../assets/icons/marker.svg";

const libraries = ["places"];
const MapComponent = ({
  cords,
  places,
  setMap,
  zoom,
  mode,
  destination,
  setInstructions,
}) => {
  useEffect(() => {
    setDefaults({
      key: GOOGLE_MAPS_API_KEY,
      language: "en",
    });
  }, []);
  const [isOpenState, setIsOpenState] = useState({
    isOpen: false,
    markerId: null,
  });
  const [directions, setDirections] = useState(null);
  // const [instructions,setInstructions] = useState(null)
  const mapStyles = {
    height: "100%",
    width: "100%",
  };

  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  });

  const toggleOpen = (index, item) => {
    setIsOpenState({
      isOpen: !isOpenState.isOpen,
      markerId: index,
    });
    // setInfoItem(item);
  };
  useEffect(() => {
    if (destination !== null) {
      calculateDirections();
    }
  }, [destination]);

  const calculateDirections = () => {
    const directionsService = new window.google.maps.DirectionsService();
    const origin = { lat: cords.lat, lng: cords.lng };
    const end = { lat: destination.lat, lng: destination.lng };
    directionsService.route(
      {
        origin: origin,
        destination: end,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result);
          setInstructions({
            totalDuration: result.routes[0].legs[0].duration,
            totalDistance: result.routes[0].legs[0].distance,
            steps: result.routes[0].legs[0].steps,
          });
        } else {
          console.error("Could not display directions");
        }
      }
    );
  };

  return (
    <>
      {/* <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}> */}
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={zoom}
          center={cords}
          onLoad={(map) => setMap(map)}
          options={{
            scrollwheel: true,
          }}
        >
          {directions && mode === "directions" && (
            <DirectionsRenderer
              directions={directions}
              options={{
                polylineOptions: {
                  strokeColor: "#192957",
                  strokeOpacity: 0.8,
                  strokeWeight: 4,
                },
                suppressMarkers: true,
              }}
            />
          )}
          {directions && mode === "directions" && (
            <>
              <Marker position={directions.routes[0].legs[0].start_location} />
              <Marker
                position={directions.routes[0].legs[0].end_location}
                icon={{
                  url: MarkerIcon,
                  size: new window.google.maps.Size(35, 35),
                  scaledSize: new window.google.maps.Size(25, 25),
                }}
              />
            </>
          )}
          {cords && mode === "markers" && (
            <Marker position={cords} onClick={() => toggleOpen("start", cords)}>
              {isOpenState.isOpen && isOpenState.markerId === "start" && (
                <InfoWindow onCloseClick={() => toggleOpen(null)}>
                  <div className={styles.info_div}>
                    <p>Start Location</p>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          )}
          {mode === "markers" &&
            places.map((item, index) => {
              return (
                <Marker
                  key={index}
                  position={item}
                  onClick={() => toggleOpen(index, item)}
                  icon={{
                    url: MarkerIcon,
                    size: new window.google.maps.Size(35, 35),
                    scaledSize: new window.google.maps.Size(25, 25),
                  }}
                >
                  {isOpenState.isOpen && isOpenState.markerId == index && (
                    <>
                      <InfoWindow
                        position={{ lat: item.lat, lng: item.lng }}
                        onCloseClick={() => toggleOpen(null)}
                      >
                        <div className={styles.info_div}>
                          <div className={styles.address_title}>
                            <h3 className={styles.title}>{item.name}</h3>
                          </div>
                          <div className={styles.address}>
                            <p className={styles.address_line}>
                              {item.vicinity}
                            </p>
                          </div>
                          <div className={styles.view_link}>
                            <a
                              target="_blank"
                              href={`https://maps.google.com/maps?q=${encodeURIComponent(
                                `${item.name}, ${item.vicinity}`
                              )}&ll=${item.lat},${item.lng}`}
                            >
                              <span>View Direction</span>
                            </a>
                          </div>
                        </div>
                      </InfoWindow>
                    </>
                  )}
                </Marker>
              );
            })}
        </GoogleMap>
      )}
      {/* </LoadScript> */}
    </>
  );
};

export default React.memo(MapComponent);
