import React, { useEffect, useRef, useState } from "react";
import { fetchContact } from "../util/fetch";
import styles from "./pages.module.css";
import { NavLink } from "react-router-dom";
import MapComponent from "../components/MapComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  setDefaults,
  fromAddress,
  geocode,
  RequestType,
  fromLatLng,
  setRegion,
} from "react-geocode";
import { BASE_URL, GOOGLE_MAPS_API_KEY } from "../config/config";
import {
  validateAddress,
  validateFirstName,
  validateLastName,
  validateEmail,
  validatePhone,
  validateComment,
} from "../config/validation";
import BudgetInfoModal from "../components/BudgetInfoModal";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY, SITE_URL } from "../config/config";
import axios from "axios";
import Loader from "../components/Loader";
import { calculateDistance } from "../util/calculateDistance";
import DirectionSteps from "../components/DirectionSteps";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const dispatch = useDispatch();
  const [contactData, setContactData] = useState({
    bannerData: {},
    contact: {},
    findNearestBudget: {},
  });
  const [budgetMarker, setBudgetMarker] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [zoom, setZoom] = useState(6);
  const [initialCords, setInitialCords] = useState({
    lat: -31.2532183,
    lng: 146.921099,
  });
  const recaptcha = useRef();
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [instructions, setInstructions] = useState(null);
  const [formData, setFormData] = useState({
    address: "",
    radius: "10",
    results: "10",
  });
  // const [contactFormData, setContactFormData] = useState();
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [infoType, setInfoType] = useState(" ");
  const [infoItem, setInfoItem] = useState({});
  const [validationError, setValError] = useState({
    invalidAddress: null,
    invalidEmail: null,
    invalidFirstName: null,
    invalidLastName: null,
    invalidPhone: null,
    invalidComment: null,
  });
  const [recaptchaErr, setCaptchaError] = useState(false);
  const [submitMsg, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [storeLocations, setStoreLocations] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [mapDisplay, setMapDisplay] = useState("markers");
  const [destination, setDestination] = useState(null);
  const formResponse = useSelector((state) => state.contactResponse);
  const setModal = (info, item) => {
    setInfoModalVisible(true);
    setInfoType(info);
    setInfoItem(item);
    document.body.classList.add("no_scroll", "modal_set");
    document.getElementById("address_div").classList.add("no_scroll");
  };
  useEffect(() => {
    setBudgetMarker([]);
    document.title = "Contact Us | Budget Petrol";
    const fetchData = async () => {
      const response = await fetchContact();
      setContactData({
        ...contactData,
        bannerData: response.data.banner,
        contact: response.data.contact,
        findNearestBudget: response.data.findNearestBudget,
      });
    };
    fetchData();
    setDefaults({
      key: GOOGLE_MAPS_API_KEY,
      language: "en",
    });
    setRegion("au");
  }, []);
  // initial marker coordinates
  useEffect(() => {
    if (addresses.length == 40) {
      const sortedAddresses = addresses.sort((a, b) => a.distance - b.distance);
      setBudgetMarker(sortedAddresses);
    }
  }, [addresses]);
  useEffect(() => {
    const setPlaces = () => {
      if (contactData.findNearestBudget.fnb_address) {
        contactData.findNearestBudget.fnb_address.map((item, index) => {
          fromAddress(item.address).then(({ results, status }) => {
            if (status === "OK") {
              const { lat, lng } = results[0].geometry.location;
              const distance = calculateDistance(
                initialCords.lat,
                initialCords.lng,
                lat,
                lng
              );
              const roundedDistance = Math.round(distance * 100) / 100; // Round to two decimal places
              const formattedDistance = roundedDistance.toFixed(1);
              // setBudgetMarker((prev) => [
              //   ...prev,
              //   {
              //     title: item.title,
              //     address: item.address,
              //     callUs: item.callUs,
              //     openingHours: item.openingHours,
              //     serviceAvailable: item.serviceAvailable,
              //     productsAvailable: item.productsAvailable,
              //     distance: formattedDistance ? formattedDistance : "",
              //     latitude: lat,
              //     longitude: lng,
              //   },
              // ]);
              setAddresses((prev) => [
                ...prev,
                {
                  title: item.title,
                  address: item.address,
                  callUs: item.callUs,
                  openingHours: item.openingHours,
                  serviceAvailable: item.serviceAvailable,
                  productsAvailable: item.productsAvailable,
                  distance: formattedDistance ? formattedDistance : "",
                  latitude: lat,
                  longitude: lng,
                },
              ]);
              setStoreLocations((prev) => [
                ...prev,
                {
                  title: item.title,
                  address: item.address,
                  callUs: item.callUs,
                  openingHours: item.openingHours,
                  serviceAvailable: item.serviceAvailable,
                  productsAvailable: item.productsAvailable,
                  distance: formattedDistance ? formattedDistance : "",
                  latitude: lat,
                  longitude: lng,
                },
              ]);
              setMarkers((prevMarker) => [
                ...prevMarker,
                {
                  lat: lat,
                  lng: lng,
                  name: item.title,
                  vicinity: item.address,
                },
              ]);
            }
          });
        });
      }
    };
    setPlaces();
  }, [contactData.findNearestBudget]);
  // Map Form Handler
  useEffect(() => {
    if (formData.address === "") {
      const sortedLocations = storeLocations.sort((a, b) => a.distance - b.distance);
      setBudgetMarker(sortedLocations);
      setMarkers([]);
      setZoom(6);
      setInitialCords({
        lat: -31.2532183,
        lng: 146.921099,
      })
      storeLocations.map((item, index) =>
        fromAddress(item.address).then(({ results, status }) => {
          if (status === "OK") {
            const { lat, lng } = results[0].geometry.location;
            setMarkers((prevMarker) => [
              ...prevMarker,
              {
                lat: lat,
                lng: lng,
                name: item.title,
                vicinity: item.address,
              },
            ]);
          }
        })
      );
      setNoResults(false);
    }
  }, [formData.address, storeLocations]);
  const handleForm = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    if (validateAddress(data.address).success == true) {
      setValError({ invalidAddress: validateAddress(data.address).message });
    } else {
      setValError({ invalidAddress: validateAddress(data.address).message });
    }
    if (data.address && validateAddress(data.address).success == true) {
      setMarkers([]);
      setBudgetMarker([]);
      setLoading(true);
      setNoResults(false);
      setInitialCords(null);
      setMapDisplay("markers");
      fromAddress(data.address)
        .then(({ results, status }) => {
          if (status === "OK") {
            const { lat, lng } = results[0].geometry.location;
            if (!map) {
              return;
            }
            const nearbyStoresDistance = storeLocations
              .map((place) => {
                const distance = calculateDistance(
                  lat,
                  lng,
                  place.latitude,
                  place.longitude
                );
                return {
                  ...place,
                  distance,
                };
              })
              .filter((place) => place.distance <= data.radius);
            const nearbyStores = nearbyStoresDistance
              .sort((a, b) => a.distance - b.distance)
              // .slice(0, data.results);
            if (nearbyStores.length > 0) {
              nearbyStores.map((item, index) => {
                setMarkers((prevMarker) => [
                  ...prevMarker,
                  {
                    lat: item.latitude,
                    lng: item.longitude,
                    name: item.title,
                    vicinity: item.address,
                  },
                ]);
                const distance = calculateDistance(
                  lat,
                  lng,
                  item.latitude,
                  item.longitude
                );
                const roundedDistance = Math.round(distance * 100) / 100; // Round to two decimal places
                const formattedDistance = roundedDistance.toFixed(1);
                setBudgetMarker((prev) => [
                  ...prev,
                  {
                    title: item.title,
                    address: item.address,
                    callUs: item.callUs,
                    openingHours: item.openingHours,
                    serviceAvailable: item.serviceAvailable,
                    productsAvailable: item.productsAvailable,
                    distance: formattedDistance ? formattedDistance : "",
                    latitude: item.latitude,
                    longitude: item.longitude,
                  },
                ]);
              });

              // setBudgetMarker(nearbyStores);
              setLoading(false);
              setInitialCords({ lat: lat, lng: lng });
              if (nearbyStores.length === 1) {
                setZoom(12);
              } else if (nearbyStores.length > 1 && nearbyStores.length <= 5) {
                setZoom(9);
              } else if (nearbyStores.length > 5 && nearbyStores <= 10) {
                setZoom(8.5);
              } else if (
                nearbyStores.length > 10 &&
                nearbyStores.length <= 25
              ) {
                setZoom(8);
              } else if (
                nearbyStores.length > 25 &&
                nearbyStores.length <= 40
              ) {
                setZoom(7.5);
              }
            } else {
              setNoResults(true);
              setLoading(false);
            }
          }
        })
        .catch((error) => {
          setNoResults(true);
          setLoading(false);
        });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };
  // Get In Touch Form Handler
  const [touchFormData, setTouchFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    comment: "",
  });
  // *** Form Validation ***
  const validateContact = (data) => {
    const captchaValue = recaptcha.current.getValue();
    if (
      !validateFirstName(data.first_name).success ||
      !validateLastName(data.last_name).success ||
      !validateEmail(data.email).success ||
      !validatePhone(data.phone).success ||
      !validateComment(data.comment).success ||
      !captchaValue
    ) {
      setValError({
        ...validationError,
        invalidFirstName: validateFirstName(data.first_name).message,
        invalidLastName: validateLastName(data.last_name).message,
        invalidEmail: validateEmail(data.email).message,
        invalidPhone: validatePhone(data.phone).message,
        invalidComment: validateComment(data.comment).message,
      });
      if (!captchaValue) {
        setCaptchaError(true);
      } else {
        setCaptchaError(false);
      }
      return false;
    } else {
      setValError({
        invalidEmail: null,
        invalidFirstName: null,
        invalidLastName: null,
        invalidPhone: null,
        invalidComment: null,
      });
      setCaptchaError(null);
    }
    return true;
  };
  const handleContactForm = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    if (validateContact(data)) {
      console.log("Data : ", data);
      const response = await axios({
        method: "post",
        url: BASE_URL,
        data: {
          model: "contactForm",
          firstname: data.first_name,
          lastname: data.last_name,
          email: data.email,
          phone: data.phone,
          comment: data.comment,
        },
      });
      if (response.data.returnCode === 1) {
        setMessage("Thankyou for your message. It has been sent!");
      } else {
        setMessage("Could not deliver your message!");
      }
      setTimeout(() => {
        // setMessage(null);
        recaptcha.current.reset();
        setTouchFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          comment: "",
        });
      }, 1500);
      // dispatch(contactFormAction(data));
    } else {
      setMessage("");
    }
  };
  const [fnameFocus, setFnameFocus] = useState(false);
  const [lnameFocus, setLnameFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [phoneFocus, setPhoneFocus] = useState(false);
  const [commentFocus, setCommentFocus] = useState(false);
  //***getting currentLocation of the user***
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log("Position :: ", position);
        const { latitude, longitude } = position.coords;
        setUserLocation({ lat: latitude, lng: longitude });
        // setInitialCords({ lat: latitude, lng: longitude });
      });
    }
  }, []);
  //*** Display Directions on Map ***
  const handleDirections = (latitude, longitude) => {
    setMapDisplay("directions");
    setDestination({ lat: latitude, lng: longitude });
  };

  return (
    <>
      <Helmet>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Contact Us | Budget Petrol | High Quality Fuel & Service Stations in NSW, Australia" />
        <meta property="og:description" content="Contact us for all your fuel needs! Whether it's Budget Petrol, Premium Unleaded, or info on local stations and Fuel 1 Card, we're here for wholesale, retail, and service inquiries." />
        <meta property="og:url" content={`${SITE_URL}contact-us`} />
        <meta property="og:site_name" content="BUDGET PETROL" />
        <meta
          property="article:modified_time"
          content="2024-05-22T06:01:40+00:00"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="1 minute" />
        <meta name="robots" content="index, follow"/>
      </Helmet>
      {/* Banner Section */}
      <div
        className={styles.inner_banner_section}
        style={{
          backgroundImage: `url(${contactData.bannerData.banner_image})`,
        }}
      >
        <div className={styles.cotact_section}>
          <div className={styles.container}>
            <div className={styles.banner_heading}>
              <h1 className={styles.banner_txt}>
                {contactData.bannerData.banner_title}
              </h1>
              <div className={styles.breadcrumb_div}>
                <p>
                  <NavLink to="/">Home </NavLink>
                  <span>Contact Us</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Contact Section */}
      <div className={styles.contact_section}>
        <div className={styles.container}>
          <div className={styles.contact_left_right}>
            <div className={styles.contact_head}>
              {" "}
              <h1 className={styles.fuel_txt}>Contact Us</h1>
            </div>
            <div className={styles.contact_wrap}>
              <div className={styles.contact_left}>
                <h2 className={styles.fuel_contact}>our office</h2>
                {contactData.contact.footer_address && (
                  <div>
                    <h3>Head Office</h3>
                    <p>{contactData.contact.footer_address}</p>
                  </div>
                )}
              </div>
              <div className={styles.contact_right}>
                {contactData.contact.footer_email && (
                  <div>
                    <div>
                      <h3>
                        For Wholesale, Retail, Customer Service or General
                        Enquiries:
                      </h3>
                    </div>
                    <div className={styles.contact_Wholesale}>
                      <div className={styles.contact_email}>
                        <p>
                          <a
                            href={`mailTo:${contactData.contact.footer_email}`}
                          >
                            {contactData.contact.footer_email}
                          </a>
                        </p>
                      </div>
                      <div className={styles.contact_number}>
                        <p>
                          <a
                            href={`tel:${contactData.contact.footer_phone_number}`}
                          >
                            {contactData.contact.footer_phone_number}
                          </a>
                        </p>
                      </div>
                      {/* <div className={styles.contact_fax}>
                        <p>
                          <a href={`fax:${contactData.contact.footer_fax}`}>
                            {contactData.contact.footer_fax}
                          </a>
                        </p>
                      </div> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Get In Touch Section */}
      <div className={styles.getInTouch_section}>
        <div className={styles.container}>
          <div className={styles.getInTouch_form}>
            <div>
              <h2>Get In Touch</h2>
            </div>
            <div className={styles.input_form}>
              <form onSubmit={handleContactForm}>
                <div className={styles.name_field}>
                  <div
                    className={
                      fnameFocus
                        ? `${styles.input_focus} ${styles.value_focus} ${styles.first_name_field}`
                        : touchFormData.firstName !== ""
                        ? `${styles.input_focus} ${styles.value_focus} ${styles.phone_field}`
                        : `${styles.first_name_field}`
                    }
                  >
                    <label>
                      First Name <em>*</em>
                    </label>
                    <input
                      type="text"
                      name="first_name"
                      value={touchFormData.firstName}
                      onFocus={() => setFnameFocus(true)}
                      onBlur={() => setFnameFocus(false)}
                      onChange={(e) =>
                        setTouchFormData({
                          ...touchFormData,
                          firstName: e.target.value,
                        })
                      }
                      className={
                        validationError.invalidFirstName && styles.error
                      }
                    />
                    {validationError.invalidFirstName && (
                      <p className={styles.error_txt}>
                        {validationError.invalidFirstName}
                      </p>
                    )}
                  </div>
                  <div
                    className={
                      lnameFocus
                        ? `${styles.input_focus} ${styles.value_focus} ${styles.last_name_field}`
                        : touchFormData.lastName !== ""
                        ? `${styles.input_focus} ${styles.value_focus} ${styles.phone_field}`
                        : `${styles.last_name_field}`
                    }
                  >
                    <label>
                      Last Name<em>*</em>
                    </label>
                    <input
                      type="text"
                      name="last_name"
                      value={touchFormData.lastName}
                      onFocus={() => setLnameFocus(true)}
                      onBlur={() => setLnameFocus(false)}
                      onChange={(e) =>
                        setTouchFormData({
                          ...touchFormData,
                          lastName: e.target.value,
                        })
                      }
                      className={
                        validationError.invalidLastName && styles.error
                      }
                    />
                    {validationError.invalidLastName && (
                      <p className={styles.error_txt}>
                        {validationError.invalidLastName}
                      </p>
                    )}
                  </div>
                </div>
                <div
                  className={
                    emailFocus
                      ? `${styles.input_focus} ${styles.value_focus} ${styles.email_name_field}`
                      : touchFormData.email !== ""
                      ? `${styles.input_focus} ${styles.value_focus} ${styles.phone_field}`
                      : `${styles.email_name_field}`
                  }
                >
                  <label>
                    Email<em>*</em>
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={touchFormData.email}
                    onFocus={() => setEmailFocus(true)}
                    onBlur={() => setEmailFocus(false)}
                    onChange={(e) =>
                      setTouchFormData({
                        ...touchFormData,
                        email: e.target.value,
                      })
                    }
                    className={validationError.invalidEmail && styles.error}
                  />
                  {validationError.invalidEmail && (
                    <p className={styles.error_txt}>
                      {validationError.invalidEmail}
                    </p>
                  )}
                </div>
                <div
                  className={
                    phoneFocus
                      ? `${styles.input_focus} ${styles.value_focus} ${styles.phone_field}`
                      : touchFormData.phone !== ""
                      ? `${styles.input_focus} ${styles.value_focus} ${styles.phone_field}`
                      : `${styles.phone_field}`
                  }
                >
                  <label>
                    Phone<em>*</em>
                  </label>
                  <input
                    type="number"
                    name="phone"
                    value={touchFormData.phone}
                    onFocus={() => setPhoneFocus(true)}
                    onBlur={() => setPhoneFocus(false)}
                    onChange={(e) =>
                      setTouchFormData({
                        ...touchFormData,
                        phone: e.target.value,
                      })
                    }
                    className={validationError.invalidPhone && styles.error}
                  />
                  {validationError.invalidPhone && (
                    <p className={styles.error_txt}>
                      {validationError.invalidPhone}
                    </p>
                  )}
                </div>
                <div
                  className={
                    commentFocus
                      ? `${styles.input_focus} ${styles.value_focus} ${styles.comment_field}`
                      : touchFormData.comment !== ""
                      ? `${styles.input_focus} ${styles.value_focus} ${styles.phone_field}`
                      : `${styles.comment_field}`
                  }
                >
                  <label>
                    Comment<em>*</em>
                  </label>
                  <textarea
                    name="comment"
                    value={touchFormData.comment}
                    onFocus={() => setCommentFocus(true)}
                    onBlur={() => setCommentFocus(false)}
                    onChange={(e) =>
                      setTouchFormData({
                        ...touchFormData,
                        comment: e.target.value,
                      })
                    }
                    className={validationError.invalidComment && styles.error}
                  />
                  {validationError.invalidComment && (
                    <p className={styles.error_txt}>
                      {validationError.invalidComment}
                    </p>
                  )}
                </div>
                <div className={styles.captcha_field}>
                  <ReCAPTCHA
                    sitekey={SITE_KEY}
                    ref={recaptcha}
                    className={recaptchaErr && styles.recaptcha_error}
                  />
                  {recaptchaErr && (
                    <p className={styles.error_txt}>
                      Verification is required!
                    </p>
                  )}
                </div>
                <div className={styles.submit_field}>
                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
          {submitMsg && (
            <div className={styles.sent_msg_section}>
              <p className={styles.submit_msg_section}>{submitMsg}</p>
            </div>
          )}
        </div>
      </div>
      {/* Find Nearest Place Section */}
      <div className={`${styles.find_nearest_section} ${styles.contact_down}`}>
        <div className={styles.container}>
          <div className={styles.nearest_heading}>
            {contactData.findNearestBudget.fnb_title && (
              <h2 className={styles.nearest_txt}>
                {contactData.findNearestBudget.fnb_title.slice(0, 12)}{" "}
                <span>{contactData.findNearestBudget.fnb_title.slice(13)}</span>
              </h2>
            )}
          </div>
          <div className={styles.nearest_main}>
            <div className={styles.nearest_img_sec}>
              <div className={styles.img_left}>
                <img
                  src={contactData.findNearestBudget.fnb_image}
                  className={styles.nearest_img}
                  alt="Nearest Place"
                />
              </div>
              <div className={styles.img_right}>
                <MapComponent
                  cords={initialCords}
                  places={markers}
                  initialAddress={contactData.findNearestBudget}
                  setMap={setMap}
                  zoom={zoom}
                  mode={mapDisplay}
                  destination={destination}
                  setInstructions={setInstructions}
                />
              </div>
            </div>
            <div className={styles.nearest_form_div}>
              <form className={styles.nearest_form} onSubmit={handleForm}>
                <div className={styles.locations_div}>
                <label className={`${styles.form_label}`}>Postcode</label>
                  <input
                    placeholder="Postcode"
                    type="text"
                    className={
                      validationError.invalidAddress
                        ? `${styles.form_input} ${styles.input_error}`
                        : `${styles.form_input}`
                    } //styles.form_input
                    name="address"
                    value={formData.address}
                    onChange={(e) =>
                      setFormData({ ...formData, address: e.target.value })
                    }
                  />
                  {validationError.invalidAddress && (
                    <p className={styles.error_txt}>
                      {validationError.invalidAddress}
                    </p>
                  )}
                </div>
                <div className={styles.radius_div}>
                  <label className={styles.form_label}>Search Radius</label>
                  <select
                    className={styles.form_input}
                    // defaultValue={10}
                    value={formData.radius}
                    onChange={(e) =>
                      setFormData({ ...formData, radius: e.target.value })
                    }
                    name="radius"
                  >
                    <option value="10">10 km</option>
                    <option value="25">25 km</option>
                    <option value="50">50 km</option>
                    <option value="100">100 km</option>
                    <option value="200">200 km</option>
                    <option value="500">500 km</option>
                  </select>
                </div>
                {/* <div className={styles.result_div}>
                  <label className={styles.form_label}>Result</label> */}
                  {/* <input
                    placeholder="10"
                    className={styles.form_input}
                    type="number"
                    value={formData.results}
                    onChange={(e) =>
                      setFormData({ ...formData, results: e.target.value })
                    }
                    name="results"
                  /> */}
                  {/* <select
                    className={styles.form_input}
                    // defaultValue={10}
                    value={formData.results}
                    onChange={(e) =>
                      setFormData({ ...formData, results: e.target.value })
                    }
                    name="results"
                  >
                    <option value="10">10 </option>
                    <option value="15">15 </option>
                    <option value="20">20 </option>
                    <option value="25">25 </option>
                    <option value="30">30 </option>
                  </select>
                </div> */}
                <div className={styles.submit_div}>
                  {/* <input type="submit" className={styles.submit_btn} /> */}
                  <button type="submit" className={styles.submit_btn}>
                    {" "}
                    Search
                  </button>
                </div>
              </form>
            </div>
            <div className={styles.address_section}>
              <div className={styles.nearest_address_sec} id="address_div">
                {loading && <Loader />}
                {noResults && (
                  <div className={styles.result_error}>
                    <p>No Results Found</p>
                  </div>
                )}
                {
                  budgetMarker.length > 0 &&
                    !loading &&
                    !noResults &&
                    mapDisplay === "markers" &&
                    budgetMarker.map((item, index) => (
                      <>
                        <div key={index} className={styles.nearest_place}>
                          <h3>{item.title}</h3>
                          <p className={styles.nearest_address}>
                            {item.address}
                          </p>
                          <p className={styles.nearest_phone}>
                            <span>Call Us:</span> {item.callUs}
                          </p>
                          <p className={styles.nearest_links}>
                            <a
                              href="javascript:void(0)"
                              onClick={() => setModal("hours", item)}
                            >
                              Opening Hours
                            </a>
                            <a
                              href="javascript:void(0)"
                              onClick={() => setModal("services", item)}
                            >
                              Services Available
                            </a>
                            <a
                              href="javascript:void(0)"
                              onClick={() => setModal("products", item)}
                            >
                              Products Available
                            </a>
                          </p>
                          <div className={styles.distance_section}>
                            <div className={styles.distance_txt}>
                              <p>{item.distance} km</p>
                            </div>
                            <div className={styles.section_directions}>
                              <p>
                                <a
                                  href="javascript:void(0)"
                                  onClick={() =>
                                    handleDirections(
                                      item.latitude,
                                      item.longitude
                                    )
                                  }
                                >
                                  Directions
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  // : contactData.findNearestBudget.fnb_address &&
                  //   !loading &&
                  //   !noResults &&
                  //   contactData.findNearestBudget.fnb_address.map(
                  //     (item, index) => (
                  //       <>
                  //         <div key={index} className={styles.nearest_place}>
                  //           <h3>{item.title}</h3>
                  //           <p className={styles.nearest_address}>
                  //             {item.address}
                  //           </p>
                  //           <p className={styles.nearest_phone}>
                  //             <span>Call Us:</span> {item.callUs}
                  //           </p>
                  //           <p className={styles.nearest_links}>
                  //             <a
                  //               href="javascript:void(0)"
                  //               onClick={() => setModal("hours", item)}
                  //             >
                  //               Opening Hours
                  //             </a>
                  //             <a
                  //               href="javascript:void(0)"
                  //               onClick={() => setModal("services", item)}
                  //             >
                  //               Services Available
                  //             </a>
                  //             <a
                  //               href="javascript:void(0)"
                  //               onClick={() => setModal("products", item)}
                  //             >
                  //               Products Available
                  //             </a>
                  //           </p>
                  //         </div>
                  //       </>
                  //     )
                  //   )
                }
                {mapDisplay === "directions" && instructions && (
                  <>
                    <DirectionSteps
                      instructions={instructions}
                      setMapDisplay={setMapDisplay}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <BudgetInfoModal
        modalVisible={infoModalVisible}
        setModalVisible={setInfoModalVisible}
        item={infoItem}
        infoType={infoType}
      />
    </>
  );
};

export default React.memo(ContactUs);
