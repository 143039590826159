import React from 'react';
import styles from "./component.module.css"
import { TailSpin, Oval } from 'react-loader-spinner';

const Loader = () => {
  return (
    <div className={styles.loader}>
        <Oval
        color='#192957'
        secondaryColor='#192957'
        width={50}
        height={50}
        radius={2}
        />
    </div>
  )
}

export default Loader