import React, { useState, useEffect, useRef } from "react";
import { fetchcommunity } from "../util/fetch";
import styles from "./pages.module.css";
import { NavLink } from "react-router-dom";
import { SITE_URL, VIDEO_URL } from "../config/config";
import { Helmet } from "react-helmet";

const Community = () => {
  const [communityData, setcommunityData] = useState({
    bannerData: {},
    partner: [],
  });
  const videoRef = useRef(null);

  useEffect(() => {
    document.title = "Community  | Budget Petrol";
    const fetchData = async () => {
      const response = await fetchcommunity();
      setcommunityData({
        ...communityData,
        bannerData: response.data.banner,
        community: response.data.community,
        supplier: response.data.supplier,
      });
    };
    fetchData();
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Community Engagement & Sponsorship | Budget Petrol Local Causes" />
        <meta property="og:description" content="Discover how Budget Petrol has been supporting local events, youth sports, and charitable causes. Join Budget Petrol in making a positive impact through local sponsorships, events, and giveaways!" />
        <meta property="og:url" content={`${SITE_URL}community`} />
        <meta property="og:site_name" content="BUDGET PETROL" />
        <meta
          property="article:modified_time"
          content="2024-05-22T06:01:40+00:00"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="2 minutes" />
        <meta name="robots" content="index, follow"/>
      </Helmet>
      {/* Banner Section */}
      <div
        className={`${styles.inner_banner_section} ${styles.banner_vid_sec}`}
        // style={{
        //   backgroundImage: `url(${communityData.bannerData.banner_image})`,
        // }}
      >
        <div className={styles.video_section}>
          <video
            loop
            autoPlay
            muted
            playsInline
            ref={videoRef}
            className={styles.video_content}
          >
            <source
              src={`${VIDEO_URL}/community.mp4`}
              type="video/mp4"
            />
          </video>
        </div>
        <div className={`${styles.container} ${styles.container_vid_div}`}>
          <div className={styles.banner_heading}>
            <h1 className={styles.banner_txt}>
              {communityData.bannerData.banner_title}
            </h1>
            <div className={styles.breadcrumb_div}>
              <p>
                <NavLink to="/">Home </NavLink>
                <span>Community</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles.our_community_Section} ${styles.community_banner_down}`}
      >
        <div className={styles.container}>
          <div
            className={`${styles.fuel_heading} ${styles.convenience_banener_heading}`}
          >
            <h1 className={`${styles.fuel_txt} ${styles.our_convenince}`}>
              Our <span>Community </span>
            </h1>
          </div>
          {communityData.community && communityData.community.length > 0 && (
            <p
              className={styles.shop_local_txt}
              dangerouslySetInnerHTML={{
                __html: communityData.community[0].story_content || "",
              }}
            ></p>
          )}
          <div className={styles.partner_images}>
            {communityData.community &&
              communityData.community.length > 0 &&
              communityData.community[0].bbs_box.map((item, index) => (
                <div className={styles.partner_img_left}>
                  <img src={item} alt="Our Community" />
                </div>
              ))}
            {
              // <>
              //   <div className={styles.partner_img_left}>
              //     <img src={communityData.community[0].bbs_box[0]} alt="" />
              //   </div>
              //   <div className={styles.partner_img_middle}>
              //     <img}
              //       src={communityData.community[0].bbs_box[1]}
              //       alt="Spot US"
              //     />
              //   </div>
              //   <div className={styles.partner_img_right}>
              //     <img
              //       src={communityData.community[0].bbs_box[2]}
              //       alt="Spot US"
              //     />
              //   </div>
              //   <div className={styles.partner_img_left}>
              //     <img
              //       src={communityData.community[0].bbs_box[3]}
              //       alt="Gift Card"
              //     />
              //   </div>
              //   <div className={styles.partner_img_left}>
              //     <img
              //       src={communityData.community[0].bbs_box[4]}
              //       alt="Gift Card"
              //     />
              //   </div>
              //   <div className={styles.partner_img_left}>
              //     <img
              //       src={communityData.community[0].bbs_box[5]}
              //       alt="Gift Card"
              //     />
              //   </div>
              //   <div className={styles.partner_img_left}>
              //     <img
              //       src={communityData.community[0].bbs_box[6]}
              //       alt="Gift Card"
              //     />
              //   </div>
              //   <div className={styles.partner_img_left}>
              //     <img
              //       src={communityData.community[0].bbs_box[7]}
              //       alt="Gift Card"
              //     />
              //   </div>
              //   <div className={styles.partner_img_left}>
              //     <img
              //       src={communityData.community[0].bbs_box[8]}
              //       alt="Gift Card"
              //     />
              //   </div>
              // </>
            }
          </div>
        </div>
      </div>

      {/* Supplier Image Section */}
      <div
        className={`${styles.supplier_page} ${styles.community_page_footer}`}
      >
        <div className={styles.container}>
          <div className={styles.supplier_gallery}>
            {communityData.supplier &&
              communityData.supplier.length > 0 &&
              // communityData.supplier.map((image, index) => (
                <>
                <div className={styles.image_wrap}>
                  <a href={`${communityData.supplier[3].supplier_link}`} target="_blank">
                    <img src={communityData.supplier[3].supplier_image} />
                  </a>
                </div>
                <div className={styles.image_wrap}>
                  <a href={`${communityData.supplier[4].supplier_link}`} target="_blank">
                    <img src={communityData.supplier[4].supplier_image} />
                  </a>
                </div>
                <div className={styles.image_wrap}>
                  <a href={`${communityData.supplier[5].supplier_link}`} target="_blank">
                    <img src={communityData.supplier[5].supplier_image} />
                  </a>
                </div>
                <div className={styles.image_wrap}>
                  <a href={`${communityData.supplier[2].supplier_link}`} target="_blank">
                    <img src={communityData.supplier[2].supplier_image} />
                  </a>
                </div>
                <div className={styles.image_wrap}>
                  <a href={`${communityData.supplier[0].supplier_link}`} target="_blank">
                    <img src={communityData.supplier[0].supplier_image} />
                  </a>
                </div>
                <div className={styles.image_wrap}>
                  <a href={`${communityData.supplier[1].supplier_link}`} target="_blank">
                    <img src={communityData.supplier[1].supplier_image} />
                  </a>
                </div>
                </>
              // ))
              }
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Community);
