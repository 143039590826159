const initialFormData = [];
const initialContactData = [];
const initialResponse = "";
export const homePageReducer = (formData = initialFormData, action) => {
  if (action.type === "nearest_place_button") {
    return [action.data, ...formData];
  }
  return formData;
};
export const contactFormReducer = (formData = initialContactData, action) => {
  if (action.type === "submit_btn") {
    return [action.data, ...formData];
  }
  return formData;
};
export const contactResReducer = (response = initialResponse,action)=>{
  if(action.type === "contact_data_res") {
    return[action.returnCode]
  }
  return response;
}
