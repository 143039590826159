import React, { useEffect, useState } from "react";
import { fetchSupplier } from "../util/fetch";
import styles from "./pages.module.css";
import { NavLink,useNavigate } from "react-router-dom";

const Suppliers = () => {
  const navigate = useNavigate();
  const [supplierData, setSupplierData] = useState({
    bannerData: {},
    supplier: [],
  });
  useEffect(() => {
    navigate("/convenience",{replace:true})
    document.title="Suppliers | Budget Petrol"
    const fetchData = async () => {
      const response = await fetchSupplier();
      setSupplierData({
        ...supplierData,
        bannerData: response.data.banner,
        supplier: response.data.supplier,
      });
    };
    fetchData();
  }, []);
  return (
    <>
      {/* Banner Section */}
      <div
        className={styles.inner_banner_section}
        style={{
          backgroundImage: `url(${supplierData.bannerData.banner_image})`,
        }}
      >
        <div className={styles.container}>
          <div className={styles.banner_heading}>
            <h1 className={styles.banner_txt}>
              {supplierData.bannerData.banner_title}
            </h1>
            <div className={styles.breadcrumb_div}>
              <p>
                <NavLink to="/">Home </NavLink>
                <span>Suppliers</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Supplier Image Section */}
      <div className={styles.supplier_page}>
        <div className={styles.container}>
          <div className={styles.up_main_heading}>
         <h2 className={styles.upper_main_heading}>
              Our <span>Story</span>
            </h2> 
          </div>
          <div className={styles.supplier_gallery}>
            
            {supplierData.supplier.length > 0 &&
              supplierData.supplier.map((image, index) => (
               <div className={styles.image_wrap}> <a href={`${image.supplier_link}`} target="_blank">
                  <img src={image.supplier_image} />
                </a></div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Suppliers);
