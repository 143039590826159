import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "./component.module.css";
import headerLogo from "../assets/images/header-logo.png";

const Header = () => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  const [sticky, setSticky] = useState("");
  const [isFixed, setIsSticky] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);
  const isSticky = () => {
    const scrollTop = window.scrollY;
    if (scrollTop >= 250) {
      setSticky("fixed");
      setIsSticky(true);
    } else {
      setIsSticky(false);
      setSticky("");
    }
  };
  const scrollTo = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 500);
  };
  return (
    <>
      <div className={`${styles.header} ${sticky}`}>
        <div className={styles.header_container}>
          <nav className={styles.header_nav}>
            <div
              className={
                isFixed
                  ? `${styles.header_main} header_main_fixed`
                  : `${styles.header_main}`
              }
            >
              <div
                className={
                  showMenu
                    ? `${styles.hamburger} ${styles.hamburger_active}`
                    : `${styles.hamburger}`
                }
                onClick={() => toggleMenu()}
              >
                <i></i>
              </div>
              <div
                className={
                  showMenu
                    ? `${styles.header_links} ${styles.header_nav_menu}`
                    : `${styles.header_links}`
                }
              >
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.nav_element} ${styles.active}`
                      : styles.nav_element
                  }
                  onClick={() => scrollTo()}
                >
                  <p
                    className={styles.nav_text}
                    onClick={() => setShowMenu(false)}
                  >
                    Home
                  </p>
                </NavLink>
                <NavLink
                  to="locations"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.nav_element} ${styles.active}`
                      : styles.nav_element
                  }
                  onClick={() => scrollTo()}
                >
                  <p
                    className={styles.nav_text}
                    onClick={() => setShowMenu(false)}
                  >
                    Locations
                  </p>
                </NavLink>
                <NavLink
                  to="our-story"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.nav_element} ${styles.active}`
                      : styles.nav_element
                  }
                  onClick={() => scrollTo()}
                >
                  <p
                    className={styles.nav_text}
                    onClick={() => setShowMenu(false)}
                  >
                    Our Story
                  </p>
                </NavLink>
                <NavLink
                  to="convenience"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.nav_element} ${styles.active}`
                      : styles.nav_element
                  }
                  onClick={() => scrollTo()}
                >
                  <p
                    className={styles.nav_text}
                    onClick={() => setShowMenu(false)}
                  >
                    Convenience
                  </p>
                </NavLink>
                <NavLink
                  to="community"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.nav_element} ${styles.active}`
                      : styles.nav_element
                  }
                  onClick={() => scrollTo()}
                >
                  <p
                    className={styles.nav_text}
                    onClick={() => setShowMenu(false)}
                  >
                    Community
                  </p>
                </NavLink>
                <NavLink
                  to="gascorp"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.nav_element} ${styles.active}  ${styles.left}`
                      : `${styles.nav_element}   ${styles.left}`
                  }
                  onClick={() => scrollTo()}
                >
                  <p
                    className={styles.nav_text}
                    onClick={() => setShowMenu(false)}
                  >
                    Gascorp
                  </p>
                </NavLink>
                <NavLink
                  to="fuel-1-card"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.nav_element} ${styles.active} ${styles.fuel_card}`
                      : `${styles.nav_element} ${styles.fuel_card}`
                  }
                  onClick={() => scrollTo()}
                >
                  <p
                    className={styles.nav_text}
                    onClick={() => setShowMenu(false)}
                  >
                    Fuel 1 Card
                  </p>
                </NavLink>
                <NavLink
                  to="fuels"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.nav_element} ${styles.active} ${styles.fuels}`
                      : `${styles.nav_element} ${styles.fuels}`
                  }
                  onClick={() => scrollTo()}
                >
                  <p
                    className={styles.nav_text}
                    onClick={() => setShowMenu(false)}
                  >
                    Fuels
                  </p>
                </NavLink>
                <NavLink
                  to="employment"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.nav_element} ${styles.active} ${styles.employment}`
                      : `${styles.nav_element} ${styles.employment}`
                  }
                  onClick={() => scrollTo()}
                >
                  <p
                    className={styles.nav_text}
                    onClick={() => setShowMenu(false)}
                  >
                    Employment
                  </p>
                </NavLink>
                <NavLink
                  to="contact-us"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.nav_element} ${styles.active} ${styles.bdgt_btn}`
                      : `${styles.nav_element} ${styles.bdgt_btn}`                  }
                  onClick={() => scrollTo()}
                >
                  <p
                    className={styles.nav_text}
                    onClick={() => setShowMenu(false)}
                  >
                    Contact Us
                  </p>
                </NavLink>
                <NavLink
                  to="locations"
                  className={({ isActive })  =>
                    isActive
                      ? `${styles.nav_element} ${styles.active} ${styles.bdgt_btn}`
                      : `${styles.nav_element} ${styles.bdgt_btn}`}
                  onClick={() => scrollTo()}
                >
                  <p
                    className={styles.nav_text}
                    onClick={() => setShowMenu(false)}
                  >
                    Find Nearest Budget
                  </p>
                </NavLink>
              </div>
              <div
                className={
                  isFixed
                    ? `${styles.header_logo} header_logo_fixed`
                    : `${styles.header_logo}`
                }
              >
                <NavLink to="/" onClick={() => scrollTo()}>
                  <img
                    src={headerLogo}
                    className={
                      isFixed
                        ? `${styles.header_logo} header_logo_fixed`
                        : `${styles.header_logo}`
                    }
                  />
                </NavLink>
              </div>
              <div className={styles.header_btn}>
                <NavLink to="contact-us" onClick={() => scrollTo()}>
                  <button className={styles.nav_btn}>Contact Us</button>
                </NavLink>
                <NavLink to="locations" onClick={() => scrollTo()}>
                  <button className={styles.nav_btn} style={{background : "#e02826"}}>
                    Find Nearest Budget
                  </button>
                </NavLink>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default React.memo(Header);
