import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/index";
import "./fonts/FuturaCyrillicBold.ttf";
import "./fonts/FuturaCyrillicBold.eot";
import "./fonts/FuturaCyrillicBold.woff2";
import "./fonts/FuturaCyrillicBold.woff";
import "./fonts/FuturaCyrillicBold.svg#FuturaCyrillicBold";
import "./fonts//FuturaCyrillicBook.ttf";
import "./fonts//FuturaCyrillicBook.eot";
import "./fonts//FuturaCyrillicBook.woff2";
import "./fonts//FuturaCyrillicBook.woff";
import "./fonts//FuturaCyrillicBook.svg#FuturaCyrillicBook";
import "./fonts/FuturaCyrillicDemi.ttf";
import "./fonts/FuturaCyrillicDemi.eot";
import "./fonts/FuturaCyrillicDemi.woff2";
import "./fonts/FuturaCyrillicDemi.woff";
import "./fonts/FuturaCyrillicDemi.svg#FuturaCyrillicDemi";
import "./fonts/FuturaCyrillicExtraBold.ttf";
import "./fonts/FuturaCyrillicExtraBold.eot";
import "./fonts/FuturaCyrillicExtraBold.woff2";
import "./fonts/FuturaCyrillicExtraBold.woff";
import "./fonts/FuturaCyrillicExtraBold.svg#FuturaCyrillicExtraBold";
import "./fonts/FuturaCyrillicLight.ttf";
import "./fonts/FuturaCyrillicLight.eot";
import "./fonts/FuturaCyrillicLight.woff2";
import "./fonts/FuturaCyrillicLight.woff";
import "./fonts/FuturaCyrillicLight.svg#FuturaCyrillicLight";
import "./fonts/FuturaCyrillicMedium.ttf";
import "./fonts/FuturaCyrillicMedium.eot";
import "./fonts/FuturaCyrillicMedium.woff2";
import "./fonts/FuturaCyrillicMedium.woff";
import "./fonts/FuturaCyrillicMedium.svg#FuturaCyrillicMedium";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
