import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import mySaga from "./saga";
import homeReducer from "./homeSlice";
import {
  contactFormReducer,
  contactResReducer,
  homePageReducer,
} from "./reducers";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    home: homeReducer,
    homePage: homePageReducer,
    contactForm: contactFormReducer,
    contactResponse: contactResReducer,
  },
  middleware: () => [sagaMiddleware],
});

sagaMiddleware.run(mySaga);
export default store;
