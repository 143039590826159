import React, { useEffect } from "react";
import styles from "./component.module.css";

const DirectionSteps = ({ instructions, setMapDisplay }) => {
  return (
    <>
      <div id="instructions_div" className={styles.instructions_sec}>
        <div className={styles.instruction_back}>
          <p>
            <a
              href="javascript:void(0)"
              onClick={() => setMapDisplay("markers")}
            >
              BACK
            </a>
          </p>
        </div>
        <div className={styles.total_section}>
          <p>
            {instructions.totalDistance.text} -{" "}
            {instructions.totalDuration.text}
          </p>
        </div>
        <div className={styles.steps_section}>
          {instructions.steps.map((item, index) => (
            <div className={styles.steps_inner}>
              <div className={styles.steps_index}> <p>{index + 1}</p></div>
              <div className={styles.steps_instruct}> <p dangerouslySetInnerHTML={{ __html: item.instructions }}></p></div>
              <div className={styles.steps_distance}> <p>{item.distance.text}</p></div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DirectionSteps;
