import { createSlice } from "@reduxjs/toolkit";
import instaImage1 from "../assets/images/home/gallary-image1.jpg";
import instaImage2 from "../assets/images/home/gallary-image2.jpg";
import instaImage3 from "../assets/images/home/gallary-image3.jpg";
import instaImage4 from "../assets/images/home/gallary-image4.jpg";

const initialState = {
  instaImages: [instaImage1, instaImage2, instaImage3, instaImage4],
};

const homeSlice = createSlice({
  name: "home",
  initialState: initialState,
  reducers: {},
});

export const homeActions = homeSlice.actions;
export default homeSlice.reducer;
