import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import rightIcon from "../assets/icons/arrow-right-circle.svg";
import leftIcon from "../assets/icons/arrow-left-circle.svg";
import styles from "./component.module.css";

const ImageSlider = ({
  slides,
  setCurrentIndex,
  currentIndex,
  numberOfSlides,
  sliderType,
}) => {
  const CustomPrevArrow = ({ onClick }) => (
    <button
      className={
        sliderType === "OurFuel"
          ? `${styles.slider_prev} ${styles.slider_prev_fuel}`
          : `${styles.slider_prev}`
      }
      onClick={onClick}
    >
      {/* <img src={leftIcon} alt="Previous"/> */}
    </button>
  );

  const CustomNextArrow = ({ onClick }) => (
    <button
      className={
        sliderType === "OurFuel"
          ? `${styles.slider_next} ${styles.slider_next_fuel}`
          : `${styles.slider_next}`
      }
      onClick={onClick}
    >
      {/* <img src={rightIcon} alt="Next"/> */}
    </button>
  );
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: numberOfSlides,
    slidesToScroll: 1,
    autoplay: sliderType === "OurFuel" ? false : true,
    autoplaySpeed: 2500,
    cssEase: "linear",
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    afterChange:
      sliderType === "OurFuel" ? (index) => setCurrentIndex(index) : null,
  };

  return (
    <Slider {...settings}>
      {sliderType === "OurFuel" &&
        slides
          .slice()
          .reverse()
          .map((item, index) => (
            <>
              <div
                className={`${styles.img_wrap} ${styles.img_wrap_fuel}`}
              >
                <img
                  src={item.ourfuels_image}
                  className={styles.fuel_image}
                  alt={item.ourfuels_title}
                />
              </div>
            </>
          ))}
      {sliderType === "BudgetFuture" &&
        slides
          .slice()
          .reverse()
          .map((item, index) => (
            <>
              <div className={`${styles.our_story_img}`}>
                <img src={item} className={styles.fuel_image} alt={" Story"} />
              </div>
            </>
          ))}
      {sliderType === "OurStory" &&
        slides
          .slice()
          .reverse()
          .map((item, index) => (
            <>
              <div className={`${styles.our_story_img}`}>
                <img src={item} className={styles.fuel_image} alt={" Story"} />
              </div>
            </>
          ))}
      {sliderType === "AboutUs" &&
        slides
          .slice()
          .reverse()
          .map((item, index) => (
            <>
              <div className={`${styles.about_us_img} ${styles.our_story_img}`}>
                <img src={item} className={styles.fuel_image} alt={" Story"} />
              </div>
            </>
          ))}
      {sliderType === "ShopLocalDesktop" &&
        slides
          .slice()
          .reverse()
          .map((item, index) => (
            <>
              <div className={`${styles.shop_local_img} ${styles.img_desktop}`}>
                <img
                  src={item.image}
                  className={styles.fuel_image}
                  alt={"Shop Local"}
                />
              </div>
            </>
          ))}
      {sliderType === "ShopLocalMobile" &&
        slides
          .slice()
          .reverse()
          .map((item, index) => (
            <>
              <div className={`${styles.shop_local_img} ${styles.img_mobile}`}>
                <img
                  src={item.image}
                  className={styles.fuel_image}
                  alt={"Shop Local"}
                />
              </div>
            </>
          ))}
      {sliderType === "Convenience" &&
        slides
          .slice()
          .reverse()
          .map((item, index) => (
            <>
              <div className={`${styles.shop_local_img}`}>
                <img
                  src={item}
                  className={styles.fuel_image}
                  alt={"Convenience"}
                />
              </div>
            </>
          ))}
    </Slider>
  );
};

export default React.memo(ImageSlider);
