import React,{useEffect,useState} from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { fetchFooter } from "../util/fetch";

function Layout() {
  const [footerData, setFooterData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchFooter();
      setFooterData(response.data);
    };
    fetchData();
  }, []);
  return (
    <>
      <Header />
      <Outlet />
      <Footer footerData={footerData}/>
    </>
  );
}

export default React.memo(Layout);
