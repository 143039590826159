import React, { useEffect, useState } from "react";
import styles from "./pages.module.css";
import { NavLink } from "react-router-dom";
import {
  validateFirstName,
  validateEmail,
  validatePhone,
  validateSite,
} from "../config/validation";
import axios from "axios";
import { BASE_URL, SITE_URL } from "../config/config";
import { Helmet } from "react-helmet";

const Comp = () => {
  useEffect(() => {
    document.title = "Comp | Budget Petrol";
  }, []);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    site: "Choose an option",
  });
  const [validationError, setValError] = useState({
    invalidEmail: null,
    invalidName: null,
    invalidSite: null,
    invalidPhone: null,
  });
  const [submitMsg, setMessage] = useState(null);
  // *** Form Validation ***
  const validateForm = (data) => {
    if (
      !validateFirstName(data.name).success ||
      !validateEmail(data.email).success ||
      !validateSite(data.site).success ||
      !validatePhone(data.phone).success
    ) {
      setValError({
        invalidName: validateFirstName(data.name).message,
        invalidEmail: validateEmail(data.email).message,
        invalidSite: validateSite(data.site).message,
        invalidPhone: validatePhone(data.phone).message,
      });
      return false;
    } else {
      setValError({
        invalidEmail: null,
        invalidName: null,
        invalidSite: null,
        invalidPhone: null,
      });
    }
    return true;
  };
  const handleForm = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    console.log("DATATATAAAAAAAAAAA : : ", data);
    if (validateForm(data)) {
      console.log("Data : ", data);
      const response = await axios({
        method: "post",
        url: BASE_URL,
        data: {
          model: "compForm",
          name: data.name,
          email: data.email,
          mobileNo: data.phone,
          budgetSite: data.site,
        },
      });
      console.log("RESPONSE : ", response);
      if (response.data.returnCode === 1) {
        setMessage("Thank you for your message. It has been sent!");
      } else {
        setMessage("Could not deliver your message!");
      }
      setTimeout(() => {
        setFormData({
          name: "",
          email: "",
          phone: "",
          site: "Choose an option",
        });
      }, 1500);
    }
    else{
      setMessage("")
    }
  };
  const [nameFocus, setNameFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [phoneFocus, setPhoneFocus] = useState(false);
  const [siteFocus, setSiteFocus] = useState(false);

  return (
    <>
      <Helmet>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Giveaway - Budget Petrol" />
        <meta property="og:url" content={`${SITE_URL}comp`} />
        <meta property="og:site_name" content="BUDGET PETROL" />
        <meta property="article:modified_time" content="2024-05-22T06:01:40+00:00" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="robots" content="index, follow"/>
      </Helmet>
      <div className={styles.giveaway_page}>
        <div className={styles.breadcrumb_div}>
          <div className={styles.container}>
            <p>
              <NavLink to="/">Home</NavLink>
              <span>Comp</span>
            </p>
          </div>
        </div>
        <div className={`${styles.comp_main} ${styles.getInTouch_section}`}>
          <div className={styles.container}>
            <div className={styles.comp_form}>
              <div>
                <h2>To Enter, complete the below fields</h2>
              </div>
              <div className={styles.input_form}>
                <form onSubmit={handleForm}>
                  <div
                    className={
                      nameFocus
                        ? `${styles.input_focus} ${styles.value_focus} ${styles.name_div}`
                        : formData.name !== ""
                        ? `${styles.input_focus} ${styles.value_focus} ${styles.phone_field}`
                        : `${styles.name_div}`
                    }
                  >
                    <label>
                      Name <em>*</em>
                    </label>
                    <input
                      type="text"
                      value={formData.name}
                      name="name"
                      onFocus={() => setNameFocus(true)}
                      onBlur={() => setNameFocus(false)}
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                      className={validationError.invalidName && styles.error}
                    />
                    {validationError.invalidName && (
                      <p className={styles.error_txt}>
                        {validationError.invalidName}
                      </p>
                    )}
                  </div>
                  <div
                    className={
                      emailFocus
                        ? `${styles.input_focus} ${styles.value_focus} ${styles.email_div}`
                        : formData.email !== ""
                        ? `${styles.input_focus} ${styles.value_focus} ${styles.phone_field}`
                        : `${styles.email_div}`
                    }
                  >
                    <label>
                      Email <em>*</em>
                    </label>
                    <input
                      type="text"
                      value={formData.email}
                      name="email"
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                      onFocus={() => setEmailFocus(true)}
                      onBlur={() => setEmailFocus(false)}
                      className={validationError.invalidEmail && styles.error}
                    />
                    {validationError.invalidEmail && (
                      <p className={styles.error_txt}>
                        {validationError.invalidEmail}
                      </p>
                    )}
                  </div>
                  <div
                    className={
                      phoneFocus
                        ? `${styles.input_focus} ${styles.value_focus} ${styles.phone_div}`
                        : formData.phone !== ""
                        ? `${styles.input_focus} ${styles.value_focus} ${styles.phone_field}`
                        : `${styles.phone_div}`
                    }
                  >
                    <label>
                      Mobile Phone<em>*</em>
                    </label>
                    <input
                      type="number"
                      value={formData.phone}
                      name="phone"
                      onChange={(e) =>
                        setFormData({ ...formData, phone: e.target.value })
                      }
                      onFocus={() => setPhoneFocus(true)}
                      onBlur={() => setPhoneFocus(false)}
                      className={validationError.invalidPhone && styles.error}
                    />
                    {validationError.invalidPhone && (
                      <p className={styles.error_txt}>
                        {validationError.invalidPhone}
                      </p>
                    )}
                  </div>
                  <div
                    className={`${styles.input_focus} ${styles.value_focus} ${styles.site_div}`}
                  >
                    <label>
                      Choose Local Budget Site<em>*</em>
                    </label>
                    <select
                      value={formData.site}
                      onChange={(e) =>
                        setFormData({ ...formData, site: e.target.value })
                      }
                      onFocus={() => setSiteFocus(true)}
                      onBlur={() => setSiteFocus(false)}
                      name="site"
                      className={validationError.invalidSite && styles.error}
                    >
                      <option value="Choose an option">Choose an option</option>
                      <option value="Budget Annangrove">
                        Budget Annangrove
                      </option>
                      <option value="Budget Ashfield">Budget Ashfield</option>
                      <option value="Budget Auburn">Budget Auburn</option>
                      <option value="Budget Bexley">Budget Bexley</option>
                      <option value="Budget Bexley Rd Campsie">Budget Bexley Rd Campsie</option>
                      <option value="Budget Blacktown">Budget Blacktown</option>
                      <option value="Budget Brighton">Budget Brighton</option>
                      <option value="Budget Bundeena">Budget Bundeena</option>
                      <option value="Budget Campsie">Budget Campsie</option>
                      <option value="Budget Carlton">Budget Carlton</option>
                      <option value="Budget Chester Hill">Budget Chester Hill</option>
                      <option value="Budget Chippendale">Budget Chippendale</option>
                      <option value="Budget Crystal St">Budget Crystal St</option>
                      <option value="Budget Dulwich Hill">Budget Dulwich Hill</option>
                      <option value="Budget Earlwood">Budget Earlwood</option>
                      <option value="Budget Eastlakes">Budget Eastlakes</option>
                      <option value="Budget Enfield">Budget Enfield</option>
                      <option value="Budget Erskineville">Budget Erskineville</option>
                      <option value="Budget Goulburn">Budget Goulburn</option>
                      <option value="Budget Granville">Budget Granville</option>
                      <option value="Budget Harbord">Budget Harbord</option>
                      <option value="Budget Hoxton Park">Budget Hoxton Park</option>
                      <option value="Budget Kingsgrove">Budget Kingsgrove</option>
                      <option value="Budget Kogarah">Budget Kogarah</option>
                      <option value="Budget Mascot">Budget Mascot</option>
                      <option value="Budget Matraville">Budget Matraville</option>
                      <option value="Budget Moorebank">Budget Moorebank</option>
                      <option value="Budget Nth Strathfield">Budget Nth Strathfield</option>
                      <option value="Budget Oatley">Budget Oatley</option>
                      <option value="Budget Penrith">Budget Penrith</option>
                      <option value="Budget Petersham">Budget Petersham</option>
                      <option value="Budget Sanctuary Point">Budget Sanctuary Point</option>
                      <option value="Budget Smithfield">Budget Smithfield</option>
                      <option value="Budget St Helens Park">Budget St Helens Park</option>
                      <option value="Budget Telopea">Budget Telopea</option>
                      <option value="Mobile Budget FaulconBridge">Mobile Budget FaulconBridge</option>
                      <option value="Mobile Budget Huskisson">Mobile Budget Huskisson</option>
                      <option value="Mobile Budget Port Kembla">Mobile Budget Port Kembla</option>
                      <option value="Mobil Budget Silverdale">Mobil Budget Silverdale</option>
                      <option value="Mobil Budget The Oaks">Mobil Budget The Oaks</option>
                      <option value="Prime Earlwood">Prime Earlwood</option>
                      <option value="Prime Wardell">Prime Wardell</option>
                    </select>
                    {validationError.invalidSite && (
                      <p className={styles.error_txt}>
                        {validationError.invalidSite}
                      </p>
                    )}
                  </div>
                  <div className={styles.submit_btn}>
                    <button type="submit">Submit</button>
                  </div>
                </form>
              </div>
            </div>
            {submitMsg && (
              <div className={styles.sent_msg_section}>
                <p className={styles.submit_msg_section}>{submitMsg}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Comp);
